import '../js/bootstrap.js';

import '../scss/main.scss';



  $(document).ready(function(){
    $("a[href*='#']").on("click", function(e){
      var anchor = $(this);
      $('html, body').stop().animate({
        scrollTop: $(anchor.attr('href')).offset().top
      }, 777);
      e.preventDefault();
      return false;
    });     
});



$(window).scroll(function() {
  if ( $(window).scrollTop() >= 0 ){
    $('.header').css('background-color', 'rgba(210, 208, 208, 1)');
  }
  if ( $(window).scrollTop() == 0 ){
    $('.header').removeAttr('style');
  }
});
